<template lang="html">
  <div class="form" v-if="!hide">
    <h1>WISDOM Esignature Generator</h1>
    <label for="name">
      Name:
      <input type="text" id="name" v-model="name">
    </label>
    <label for="position">
      Position:
      <input type="text" id="position" v-model="position">
    </label>
    <label for="phone">
      Phone:
      <input type="text" id="phone" v-model="phone">
    </label>
    <label for="mobile">
      Mobile:
      <input type="text" id="mobile" v-model="mobile">
    </label>
    <label for="email">
      Email:
      <input type="text" id="email" v-model="email">
    </label>
    <label for="style">
      Style
      <select name="style" id="style" v-model="selectedStyle">
        <option v-for="style in styles" :key="style" :value="style" v-html="style"></option>
      </select>
    </label>
    <button @click="hide = true">Generate</button>
  </div>
  <table width="600" cellpadding="0" cellspacing="0">
    <tr>
      <td>
        <table cellpadding="10" cellspacing="0" bgcolor="#000000" width="600">
          <tr>
            <td width="100">
              <img width="100" src="https://cdn.wisdom.com.au/wisdom/esignature/wisdom.gif" alt="">
            </td>
            <td width="370">
              <h2 style="color: #ffffff; text-transform: capitalize; margin: 0; font-family: Georgia, serif; letter-spacing: 2px; font-weight: bold; font-size: 28px;padding:3px;" v-html="name"></h2>
              <p style="color: #ffffff; text-transform: uppercase; font-size: 14px; margin: 0; font-family: 'helvetica', sans-serif;padding:3px;" v-html="position"></p>
            </td>
            <td align="right" width="100">
              <img width="100" :src="`https://cdn.wisdom.com.au/wisdom/esignature/${selectedStyle}/line.png`" alt="wisdom tag line">
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td>
        <table cellpadding="0" cellspacing="0" width="600">
          <tr>
            <td>
              <p style="height: 10px; margin: 0; padding: 0;">&nbsp;</p>
              <p style="color: #000000; font-family: 'helvetica', sans-serif; font-size: 15px; margin: 0; line-height: 30px;">
                <a style="text-decoration: none !important; color: #000000;" v-if="phone" :href="`tel:${phone}`">P. {{ phone }}&nbsp;&nbsp;</a>
                <a style="text-decoration: none !important; color: #000000;" v-if="mobile" :href="`tel:${mobile}`">M. {{ mobile }}&nbsp;&nbsp;</a>
                <a style="text-decoration: none !important; color: #000000;" v-if="email" :href="`mailto:${email}`">E. {{ email }} </a>
              </p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td>
        <table width="600" cellpadding="0" cellspacing="0">
          <tr>
            <td>
              <p style="color: #000000; font-family: 'helvetica', sans-serif; font-size: 15px; margin: 0; line-height: 30px;">
                <a style="text-decoration: none !important; color: #000000;" href="https://www.google.com/maps/place/66+Church+St,+Wollongong+NSW+2500/@-34.4227321,150.8935968,17z/data=!3m1!4b1!4m5!3m4!1s0x6b1319a62aafb14d:0x66477319a353816a!8m2!3d-34.4227321!4d150.8957855">
                  66 Church St, Wollongong NSW 2500
                </a>&nbsp;&nbsp;
                PO Box 149, Wollongong NSW 2520
              </p>
              <p style="height: 10px; margin: 0; padding: 0;">&nbsp;</p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td>
        <table width="600" cellpadding="0" cellspacing="0">
          <tr>
            <td width="150">
              <a style="text-decoration: none !important;" href="https://www.facebook.com/wisdomadvertising">
                <img width="30" :src="`https://cdn.wisdom.com.au/wisdom/esignature/${selectedStyle}/fb.png`" alt="Facebook">
              </a>
              <a style="text-decoration: none !important; padding: 7px;" href="https://www.instagram.com/wisdomstudio/">
                <img width="30" :src="`https://cdn.wisdom.com.au/wisdom/esignature/${selectedStyle}/ig.png`" alt="Instagram">
              </a>
              <a style="text-decoration: none !important;" href="https://www.linkedin.com/company/wisdom-advertising-&-design">
                <img width="30" :src="`https://cdn.wisdom.com.au/wisdom/esignature/${selectedStyle}/in.png`" alt="LinkedIn">
              </a>
              <a style="text-decoration: none !important; padding: 7px;" href="https://www.youtube.com/user/WisdomAdvertising">
                <img width="30" :src="`https://cdn.wisdom.com.au/wisdom/esignature/${selectedStyle}/yt.png`" alt="Youtube">
              </a>
            </td>
            <td>
              <a style="text-decoration: none !important; color: #000000; font-family: 'helvetica', sans-serif; font-weight: bold; letter-spacing: 1px;" href="https://www.wisdom.com.au">WISDOM.COM.AU</a>
            </td>
            <td align="right">
              <a style="text-decoration: none !important;" href="https://www.wisdom.com.au">
                <img width="114" src="https://cdn.wisdom.com.au/wisdom/esignature/wisdom-logo.png" alt="WISDOM">
              </a>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td>
        <table>
          <tr>
            <td>
              <p style="height: 10px; margin: 0; padding: 0;">&nbsp;</p>
              <p style="color: #999999; font-size: 10px; line-height: 1.5; font-style: italic; font-family: 'helvetica', sans-serif;margin:0">This email (including attachments) is solely for the intended recipient & may contain confidential, legally privileged and/or copyright information. If you are not the intended recipient any dissemination, copying, use or disclosure of the information included in this message is prohibited. If you have received this communication in error please notify the sender immediately & permanently delete this email.</p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      position: '',
      email: '',
      phone: '02 4226 5655',
      mobile: '',
      address: '66 Church St, Wollongong NSW 2500 PO Box 149, Wollongong NSW 2500',
      color: 'pink',
      hide: false,
      styles: ['watermelon', 'bubblegum', 'indigo', 'periwinkle', 'sunshine', 'turquoise'],
      selectedStyle: 'watermelon'
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-flow: column;
  margin-bottom: 50px;

  label {
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;
    width: 200px;
  }

  button {
    width: 100px;
  }
}
</style>
